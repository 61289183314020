import { Navigate, Outlet } from 'react-router-dom';

const RedirectedRoute = ({ redirectPath = '/', children }) => {
  const userPhone = localStorage.getItem('user-phone');
  const userToken = localStorage.getItem('user-token');
  if (userPhone && userToken) return <Navigate to={redirectPath} replace />;
  else return children ? children : <Outlet />;
};

export default RedirectedRoute;
